import { ofType } from 'redux-observable'
import { EMPTY, from, iif, of } from 'rxjs'
import { catchError, delay, filter, map, mergeMap, takeUntil, withLatestFrom } from 'rxjs/operators'

import {
  createRoom,
  endRoom,
  getStudies,
  getStudy,
  invitationResponse,
  inviteParticipants,
  joinRoom
} from '@/services/api'
import {
  CONFERENCE_GET_STUDIES,
  CONFERENCE_GET_STUDIES_FULFILLED,
  CONFERENCE_GET_STUDIES_REJECTED,
  CONFERENCE_UPDATE_INVITED_USER_INVITATION_STATUS,
  CONFERENCE_UPDATE_INVITED_USER_INVITATION_STATUS_FULFILLED,
  CONFERENCE_UPDATE_INVITED_USER_INVITATION_STATUS_REJECTED,
  CREATE_ROOM,
  CREATE_ROOM_FULFILLED,
  CREATE_ROOM_REJECTED,
  END_ROOM,
  END_ROOM_FULFILLED,
  END_ROOM_REJECTED,
  INVITATION_RESPONSE,
  INVITATION_RESPONSE_FULFILLED,
  INVITATION_RESPONSE_REJECTED,
  INVITE_PARTICIPANTS,
  INVITE_PARTICIPANTS_FULFILLED,
  INVITE_PARTICIPANTS_REJECTED,
  JOIN_ROOM_FULFILLED,
  JOIN_ROOM_REJECTED,
  LEAVE_ROOM,
  NEW_NOTIFICATION_INCOMING,
  SET_TWILIO_CONFERENCE_ROOM
} from '@/store/types'
import {
  CONFERENCE,
  CONFERENCE_INVITATION_RESPONSE,
  CONFERENCE_INVITATION_RESPONSE_WAITING_TIME
} from '@/constants'
import { REDUCER_STATE } from '@/store/constants'
import { SYSTEM_NOTIFICATION_FIELDS } from '@/constants/api-data/rawNotification'
import { NOTIFICATION_TYPES } from '@/services/constants'
import { USER_FIELDS } from '@/constants/api-data'

const conferenceEpics = {
  createRoom: ({ studyId, roomName, roomType, conferenceType }) => ({
    type: CREATE_ROOM,
    payload: { studyId, roomName, roomType, conferenceType }
  }),

  createRoomFulfilled: (payload) => ({
    type: CREATE_ROOM_FULFILLED,
    payload
  }),

  createRoomError: (error) => ({
    type: CREATE_ROOM_REJECTED,
    payload: error
  }),

  createRoomEpic: (action$) =>
    action$.pipe(
      ofType(CREATE_ROOM),
      mergeMap((action) =>
        from(createRoom(action.payload)).pipe(
          map((roomData) => conferenceEpics.createRoomFulfilled(roomData)),
          catchError((error) => of(conferenceEpics.createRoomError(error)))
        )
      )
    ),

  inviteParticipants: (payload) => ({
    type: INVITE_PARTICIPANTS,
    payload
  }),
  inviteParticipantsFulfilled: (payload) => ({
    type: INVITE_PARTICIPANTS_FULFILLED,
    payload
  }),
  inviteParticipantsError: (error) => ({
    type: INVITE_PARTICIPANTS_REJECTED,
    payload: error
  }),
  inviteParticipantsEpic: (action$) =>
    action$.pipe(
      ofType(INVITE_PARTICIPANTS),
      mergeMap((action) =>
        from(inviteParticipants(action.payload)).pipe(
          map(conferenceEpics.inviteParticipantsFulfilled),
          catchError((error) => of(conferenceEpics.inviteParticipantsError(error)))
        )
      )
    ),

  responseInvitation: ({ studyId, roomName, response }) => ({
    type: INVITATION_RESPONSE,
    payload: { studyId, roomName, response }
  }),
  responseInvitationFulfilled: (payload) => ({
    type: INVITATION_RESPONSE_FULFILLED,
    payload
  }),
  responseInvitationError: (error) => ({
    type: INVITATION_RESPONSE_REJECTED,
    payload: error
  }),
  responseInvitationEpic: (action$) =>
    action$.pipe(
      ofType(INVITATION_RESPONSE),
      mergeMap((action) =>
        from(invitationResponse(action.payload)).pipe(
          map(conferenceEpics.responseInvitationFulfilled),
          catchError((error) => of(conferenceEpics.responseInvitationError(error)))
        )
      )
    ),

  // Join the conference after accepting the call
  joinRoomFulfilled: (payload) => ({
    type: JOIN_ROOM_FULFILLED,
    payload
  }),
  joinRoomError: (error) => ({
    type: JOIN_ROOM_REJECTED,
    payload: error
  }),
  joinRoomEpic: (action$) =>
    action$.pipe(
      ofType(INVITATION_RESPONSE_FULFILLED),
      mergeMap((action) =>
        iif(
          () => action.payload.response === CONFERENCE_INVITATION_RESPONSE.ACCEPT,
          of(action.payload).pipe(
            mergeMap(() =>
              from(joinRoom(action.payload)).pipe(
                map((roomData) => conferenceEpics.joinRoomFulfilled(roomData)),
                catchError((error) => of(conferenceEpics.joinRoomError(error)))
              )
            )
          ),
          EMPTY
        )
      )
    ),

  leaveRoom: ({ studyId, roomName }) => ({
    type: LEAVE_ROOM,
    payload: { studyId, roomName }
  }),

  endRoom: ({ studyId, roomName }) => ({
    type: END_ROOM,
    payload: { studyId, roomName }
  }),
  endRoomFulfilled: (payload) => ({
    type: END_ROOM_FULFILLED,
    payload
  }),
  endRoomError: (error) => ({
    type: END_ROOM_REJECTED,
    payload: error
  }),
  endRoomEpic: (action$) =>
    action$.pipe(
      ofType(END_ROOM),
      mergeMap((action) =>
        from(endRoom(action.payload)).pipe(
          map((roomData) => conferenceEpics.endRoomFulfilled(roomData)),
          catchError((error) => of(conferenceEpics.endRoomError(error)))
        )
      )
    ),

  getStudies: (payload) => ({
    type: CONFERENCE_GET_STUDIES,
    payload
  }),

  getStudiesFulfilled: (payload) => ({
    type: CONFERENCE_GET_STUDIES_FULFILLED,
    payload
  }),

  getStudiesError: (error) => ({
    type: CONFERENCE_GET_STUDIES_REJECTED,
    payload: error
  }),

  getStudiesEpic: (action$) =>
    action$.pipe(
      ofType(CONFERENCE_GET_STUDIES),
      mergeMap((action) =>
        iif(
          () => action.payload.canManageStudiesUsers,
          of(action.payload.populate).pipe(
            mergeMap((payload) =>
              from(getStudies(payload)).pipe(
                map(conferenceEpics.getStudiesFulfilled),
                catchError((error) => of(conferenceEpics.getStudiesError(error)))
              )
            )
          ),
          of(action.payload).pipe(
            mergeMap((payload) =>
              from(getStudy(payload.id, payload.populate)).pipe(
                map((payload) => conferenceEpics.getStudiesFulfilled([payload])),
                catchError((error) => of(conferenceEpics.getStudiesError(error)))
              )
            )
          )
        )
      )
    ),

  updateInvitedUserInvitationStatus: (payload) => ({
    type: CONFERENCE_UPDATE_INVITED_USER_INVITATION_STATUS,
    payload
  }),

  updateInvitedUserInvitationStatusFulfilled: (payload) => ({
    type: CONFERENCE_UPDATE_INVITED_USER_INVITATION_STATUS_FULFILLED,
    payload
  }),

  updateInvitedUserInvitationStatusError: (error) => ({
    type: CONFERENCE_UPDATE_INVITED_USER_INVITATION_STATUS_REJECTED,
    payload: error
  }),

  updateInvitedUserInvitationStatusEpic: (action$, state$) =>
    action$.pipe(
      ofType(CONFERENCE_UPDATE_INVITED_USER_INVITATION_STATUS, INVITE_PARTICIPANTS_FULFILLED),
      filter((action) => action.payload !== undefined),
      withLatestFrom(state$),
      map(([action, state]) => [
        action,
        state.getIn([REDUCER_STATE.CONFERENCE.NAME, REDUCER_STATE.CONFERENCE.FIELDS.INVITED_USERS])
      ]),
      mergeMap(([action, invitedUsers]) => {
        let response
        switch (action.type) {
          case CONFERENCE_UPDATE_INVITED_USER_INVITATION_STATUS:
            break
          case INVITE_PARTICIPANTS_FULFILLED:
            {
              const { newParticipant, studyId, roomName } = action.payload
              if (newParticipant) {
                const idx = invitedUsers.findIndex(
                  (user) => user?.[USER_FIELDS.ID] === newParticipant?.[USER_FIELDS.ID]
                )
                if (idx !== -1) {
                  invitedUsers[idx] = {
                    ...invitedUsers[idx],
                    [CONFERENCE.PARTICIPANT_INVITATION_STATUS_FIELD_IN_USER]:
                      CONFERENCE.PARTICIPANT_INVITATION_STATUS.WAIT_FOR_RESPONSE
                  }
                  response = conferenceEpics.updateInvitedUserInvitationStatusFulfilled({
                    invitedUsers,
                    updatedUser: invitedUsers[idx],
                    studyId,
                    roomName
                  })
                }
              }
            }
            break
          default:
            break
        }
        if (response) {
          return of(response)
        }
        return EMPTY
      })
    ),

  // Update user invitation status to NO_RESPONSE after user invitation status changed to WAIT_FOR_RESPONSE in 60s
  // update if there is no accept/reject/no response notification in 60s
  listenOnUserInvitationStatusChangeEpic: (action$, state$) =>
    action$.pipe(
      ofType(CONFERENCE_UPDATE_INVITED_USER_INVITATION_STATUS_FULFILLED),
      filter(
        (action) =>
          action.payload?.updatedUser?.[CONFERENCE.PARTICIPANT_INVITATION_STATUS_FIELD_IN_USER] ===
          CONFERENCE.PARTICIPANT_INVITATION_STATUS.WAIT_FOR_RESPONSE
      ),
      mergeMap((action) =>
        of(action).pipe(
          delay(CONFERENCE_INVITATION_RESPONSE_WAITING_TIME),
          withLatestFrom(state$),
          map(([action, state]) => [
            action,
            state.getIn([
              REDUCER_STATE.CONFERENCE.NAME,
              REDUCER_STATE.CONFERENCE.FIELDS.INVITED_USERS
            ]),
            state.getIn([REDUCER_STATE.CONFERENCE.NAME, REDUCER_STATE.CONFERENCE.FIELDS.DATA])
          ]),
          mergeMap(([action, invitedUsers, data]) => {
            const { updatedUser, roomName, studyId } = action.payload
            if (data?.roomName === roomName && data?.studyId === studyId && updatedUser) {
              const idx = invitedUsers.findIndex((user) => user._id === updatedUser._id)
              if (idx !== -1) {
                invitedUsers[idx] = {
                  ...invitedUsers[idx],
                  [CONFERENCE.PARTICIPANT_INVITATION_STATUS_FIELD_IN_USER]:
                    CONFERENCE.PARTICIPANT_INVITATION_STATUS.NO_RESPONSE
                }
                return of(
                  conferenceEpics.updateInvitedUserInvitationStatusFulfilled({
                    invitedUsers: [...invitedUsers],
                    updatedUser: invitedUsers[idx],
                    studyId,
                    roomName
                  })
                )
              }
            }
            return EMPTY
          }),
          takeUntil(
            action$.pipe(
              ofType(NEW_NOTIFICATION_INCOMING),
              filter(
                ({ payload: notification }) =>
                  notification?.[SYSTEM_NOTIFICATION_FIELDS.DATA.name]?.conferenceName ===
                    action.payload?.roomName &&
                  notification?.[SYSTEM_NOTIFICATION_FIELDS.DATA.name]?.conferenceStudyId ===
                    action.payload?.studyId &&
                  notification[SYSTEM_NOTIFICATION_FIELDS.SENDER.name]?._id ===
                    action.payload?.updatedUser._id
              )
            )
          )
        )
      )
    ),

  listenOnInvitationResponseNotification: (action$, state$) =>
    action$.pipe(
      ofType(NEW_NOTIFICATION_INCOMING),
      filter(
        ({ payload: notification }) =>
          notification?.[SYSTEM_NOTIFICATION_FIELDS.TYPE.name] ===
          NOTIFICATION_TYPES.CONFERENCE_INVITATION_RESPONSE
      ),
      withLatestFrom(state$),
      map(([action, state]) => [
        action,
        state.getIn([REDUCER_STATE.CONFERENCE.NAME, REDUCER_STATE.CONFERENCE.FIELDS.INVITED_USERS]),
        state.getIn([REDUCER_STATE.CONFERENCE.NAME, REDUCER_STATE.CONFERENCE.FIELDS.DATA])
      ]),
      mergeMap(([action, invitedUsers, data]) => {
        const notification = action.payload
        const userIdx = invitedUsers.findIndex(
          (user) => user._id === notification[SYSTEM_NOTIFICATION_FIELDS.SENDER.name]?._id
        )
        const invitationResponseKey = Object.keys(CONFERENCE_INVITATION_RESPONSE).find(
          (key) =>
            CONFERENCE_INVITATION_RESPONSE[key] ===
            notification?.[SYSTEM_NOTIFICATION_FIELDS.DATA.name]?.response
        )
        if (
          notification?.[SYSTEM_NOTIFICATION_FIELDS.DATA.name]?.conferenceName === data?.roomName &&
          notification?.[SYSTEM_NOTIFICATION_FIELDS.DATA.name]?.conferenceStudyId ===
            data?.studyId &&
          userIdx !== -1 &&
          invitationResponseKey
        ) {
          invitedUsers[userIdx] = {
            ...invitedUsers[userIdx],
            [CONFERENCE.PARTICIPANT_INVITATION_STATUS_FIELD_IN_USER]:
              CONFERENCE.PARTICIPANT_INVITATION_STATUS[invitationResponseKey]
          }
          return of(
            conferenceEpics.updateInvitedUserInvitationStatusFulfilled({
              invitedUsers: [...invitedUsers],
              studyId: data.studyId,
              roomName: data.roomName
            })
          )
        }
        return EMPTY
      })
    ),

  setTwilioConferenceRoom: (payload) => ({
    type: SET_TWILIO_CONFERENCE_ROOM,
    payload
  })
}

export default conferenceEpics
