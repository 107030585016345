export const CREATE_ROOM = 'CREATE_ROOM'
export const CREATE_ROOM_FULFILLED = 'CREATE_ROOM_FULFILLED'
export const CREATE_ROOM_REJECTED = 'CREATE_ROOM_REJECTED'

export const INVITE_PARTICIPANTS = 'INVITE_PARTICIPANTS'
export const INVITE_PARTICIPANTS_FULFILLED = 'INVITE_PARTICIPANTS_FULFILLED'
export const INVITE_PARTICIPANTS_REJECTED = 'INVITE_PARTICIPANTS_REJECTED'

export const INVITATION_RESPONSE = 'INVITATION_RESPONSE'
export const INVITATION_RESPONSE_FULFILLED = 'INVITATION_RESPONSE_FULFILLED'
export const INVITATION_RESPONSE_REJECTED = 'INVITATION_RESPONSE_REJECTED'

export const JOIN_ROOM_FULFILLED = 'JOIN_ROOM_FULFILLED'
export const JOIN_ROOM_REJECTED = 'JOIN_ROOM_REJECTED'

export const LEAVE_ROOM = 'LEAVE_ROOM'

export const END_ROOM = 'END_ROOM'
export const END_ROOM_FULFILLED = 'END_ROOM_FULFILLED'
export const END_ROOM_REJECTED = 'END_ROOM_REJECTED'

export const CONFERENCE_GET_STUDIES = 'CONFERENCE_GET_STUDIES'
export const CONFERENCE_GET_STUDIES_FULFILLED = 'CONFERENCE_GET_STUDIES_FULFILLED'
export const CONFERENCE_GET_STUDIES_REJECTED = 'CONFERENCE_GET_STUDIES_REJECTED'

// export const CONFERENCE_SET_INVITED_USERS = 'CONFERENCE_SET_INVITED_USERS'

export const CONFERENCE_UPDATE_INVITED_USER_INVITATION_STATUS =
  'CONFERENCE_UPDATE_INVITED_USER_INVITATION_STATUS'
export const CONFERENCE_UPDATE_INVITED_USER_INVITATION_STATUS_FULFILLED =
  'CONFERENCE_UPDATE_INVITED_USER_INVITATION_STATUS_FULFILLED'
export const CONFERENCE_UPDATE_INVITED_USER_INVITATION_STATUS_REJECTED =
  'CONFERENCE_UPDATE_INVITED_USER_INVITATION_STATUS_REJECTED'

export const SET_TWILIO_CONFERENCE_ROOM = 'SET_TWILIO_CONFERENCE_ROOM'
