import API from '@/services/api/client'
import ENDPOINTS from '@/services/api/endpoints'
import { errorHandler } from '@/services/api/utils'

const postLog = async ({ studyId, userId, eventMessage, eventType }) => {
  if (!studyId || !userId || !eventMessage || !eventType) return
  const body = {
    studyId,
    userId,
    eventMessage,
    eventType
  }
  try {
    await API.post(ENDPOINTS.LOGGER, body)
  } catch (err) {
    errorHandler(err)
  }
}

const logApi = {
  postLog
}

export { logApi }
